class Post {
    id: string;
    text: string;
    date: string;

    constructor(id: string, text: string, date: string) {
        this.id = id;
        this.text = text;
        this.date = date;
    }

    toString(){
        return this.id + ", " + this.text + ", " + this.date;
    }
}
export default Post;