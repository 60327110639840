import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from "@mui/material/Box";

export const OpeningHoursBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    lineHeight: "2",
    fontSize: "0.8em"
});
