import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Card} from "@mui/material";

type TextCardProps = {
    titleText: string,
    paragraph: string
}

const TextCard = ({titleText, paragraph}: TextCardProps) => {

    return (
        <Card sx={{
            width: "20em",
            height: "28em",
            padding: "3em",
            fontSize: {xs: "0.9em", md: "1.3em"},
            boxSizing: "border-box"
        }}>
            <Typography sx={{fontSize: "1.5em"}}>{titleText}</Typography>
            <Box sx={{
                backgroundColor: "red",
                width: "7em",
                height: "2px"
            }}></Box>
            <br></br>
            <br></br>
            <a style={{lineHeight: "1.5"}}>{paragraph}</a>
        </Card>
    );
};
export default TextCard;
