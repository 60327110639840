import Box from "@mui/material/Box";
import React, {useContext, useState} from "react";
import mini1 from "../resources/thumbnails/1_mini.jpg";
import mini2 from "../resources/thumbnails/2_mini.jpg";
import mini3 from "../resources/thumbnails/3_mini.jpg";
import mini4 from "../resources/thumbnails/4_mini.jpg";
import mini5 from "../resources/thumbnails/5_mini.jpg";
import mini6 from "../resources/thumbnails/6_mini.jpg";
import image1 from "../resources/images/1.jpg";
import image2 from "../resources/images/2.jpg";
import image3 from "../resources/images/3.jpg";
import image4 from "../resources/images/4.jpg";
import image5 from "../resources/images/5.jpg";
import image6 from "../resources/images/6.jpg";
import {Link} from "@mui/material";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

const Gallery = () => {

    const [currentIndex, setCurrentIndex] = useState(0);
    let thumbnails = [mini1, mini2, mini3, mini4, mini5, mini6]
    let images = [image1, image2, image3, image4, image5, image6]

    return (
        <Box id={"gallery"}>
            <Box sx={{
                display: {xs: "none", sm: "flex"},
                justifyContent: "space-evenly",
                flexDirection: "column",
                height: "auto",
                padding: {xs: "5vw 0"}
            }}>
                <Box sx={{
                    width: "100vw",
                    display: "flex",
                    justifyContent: 'space-evenly',
                    flexDirection: "row",
                    flexWrap: "wrap",
                    rowGap: "5vw"
                }}>
                    {thumbnails.map((thumbnail, index) => {
                        return <Link href={images[index]}><Box sx={{
                            fontSize: {xs: "0.7em", md: "1em"},
                            width: {xs: "26em", md: "27vw"},
                            "&:hover": {
                                boxShadow: "0 7px 4px rgba(0,0,0,.5)"
                            }
                        }} component={"img"} src={thumbnail}></Box></Link>
                    })}

                </Box>
            </Box>
            <Box sx={{
                display: {xs: "grid", sm: "none"},
                gridTemplateColumns: {xs: "1fr"},
                position: "relative",
            }}>
                <AwesomeSlider style={{width: "100vw"}} bullets={false}
                               organicArrows={true} buttons={true} infinite={true} onTransitionEnd={(slider) => {
                    setCurrentIndex(slider.currentIndex);
                }}>
                    {thumbnails.map((thumbnail, index) => {
                        return <div
                            style={{backgroundImage: `url(${thumbnail})`, width: "100vw", backgroundSize: "cover"}}>
                        </div>
                    })}
                </AwesomeSlider>
                <div style={{position: "absolute", zIndex: "2", width: "100vw", display: "flex", justifyContent: "center", bottom: "10px"}}>
                    <Box sx={{display: "flex", flexDirection: "row"}}>
                        {[...Array(thumbnails.length)].map((e, i) => {
                            if (i === currentIndex) {
                                return <div style={{
                                    borderRadius: "50%",
                                    backgroundColor: "red",
                                    height: "15px",
                                    width: "15px",
                                    margin: "0 5px"
                                }}></div>
                            } else {
                                return <div style={{
                                    borderRadius: "50%",
                                    backgroundColor: "gray",
                                    height: "15px",
                                    width: "15px",
                                    margin: "0 5px"
                                }}></div>
                            }
                        })}
                    </Box>
                </div>
            </Box>
        </Box>
    )
        ;
};
export default Gallery;