import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Card, Icon, Link} from "@mui/material";
import {Facebook} from "@mui/icons-material";


const ContactCard = () => {

    return (
        <Card sx={{
            width: "20em",
            height: "28em",
            padding: "3em",
            fontSize: {xs: "0.9em", md: "1.3em"},
            boxSizing: "border-box"
        }}>
            <Typography sx={{fontSize: "1.5em"}}>Kontakt</Typography>
            <Box sx={{
                backgroundColor: "red",
                width: "7em",
                height: "2px"
            }}></Box>
            <br></br>
            <br></br>
            <Typography sx={{fontSize: "0.8em"}}>Adresa</Typography>
            <a>Hradební 326</a>
            <br></br>
            <a>28802 Nymburk</a>
            <br></br>
            <br></br>
            <Typography sx={{fontSize: "0.8em"}}>Telefon</Typography>
            <a>+420 737 980 135</a>
            <br></br>
            <br></br>
            <Typography sx={{fontSize: "0.8em"}}>Email</Typography>
            <a>koralkynahradbach@gmail.com</a>
            <br/>
            <br/>
            <Link href={"https://www.facebook.com/Korálky-na-hradbách-183807308488709/"}><Facebook
                sx={{fontSize: "3em", "&:hover": {color: "#48F"}}}/></Link>
        </Card>
    );
};
export default ContactCard;
