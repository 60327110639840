import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Card} from "@mui/material";


const MapCard = () => {

    return (
        <Card sx={{
            width: "20em",
            height: "28em",
            padding: "3em",
            fontSize: {xs: "0.9em", md: "1.3em"},
            boxSizing: "border-box"
        }}>
            <Typography sx={{fontSize: "1.5em"}}>Mapa</Typography>
            <Box sx={{
                backgroundColor: "red",
                width: "7em",
                height: "2px"
            }}></Box>
            <br></br>
            <br></br>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2554.710447755483!2d15.03559971585702!3d50.185249914318994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470c0f76a7ac6c5b%3A0xbfbef6974e347a5b!2sHradebn%C3%AD+326%2F26%2C+288+02+Nymburk!5e0!3m2!1sen!2scz!4v1524939251162"
                style={{width: "100%", height: "18em", border: "none"}}></iframe>
        </Card>
    );
};
export default MapCard;
