import {addDoc, collection, deleteDoc, doc, getDocs, query} from "firebase/firestore";
import {db} from "../firebase";
import Post from "../model/Post";
import {toast} from "react-toastify";

class PostService {
    public static async getPosts() {
        try {
            posts = [];
            const q = query(collection(db, 'posts/'));
            const docs = await getDocs(q);
            var posts: Post[] = [];
            docs.forEach((doc) => {
                console.log(doc.data())
                let post = new Post(doc.id, doc.data().text, doc.data().date);
                posts.push(post);
            })
            return posts;
        } catch (e) {
            throw e;
        }
    }

    public static async sendPost (text: string, date: string) {
        try {
            await addDoc(collection(db, 'posts/'), {
                text: text,
                date: date,
            });
        } catch (e) {
            throw e;
        }
    }

    public static async deletePost (post: Post){
        try {
            await deleteDoc(doc(db, 'posts', post.id))
        } catch (e) {
            throw e;
        }
    }
}
export default PostService;