import React, {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {useNavigate} from "react-router-dom";
import {auth, db, logout} from "./firebase";
import {Grid, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {addDoc, collection, getDocs, query, where, deleteDoc, doc} from "firebase/firestore";
import firebase from "firebase/compat/app";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import Box from "@mui/material/Box";
import Post from "./model/Post";
import PostService from "./service/PostService";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import Typography from "@mui/material/Typography";

function Admin() {
    const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'numeric', day: 'numeric'};
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [posts, setPosts] = useState<Post[]>([]);
    const [date, setDate] = useState((new Date()).toLocaleString("cs-CZ", options));
    const [text, setText] = useState("");

    const getPosts = async () => {
        try {
            const posts = await PostService.getPosts();
            setPosts(posts);
        } catch (error) {
            console.log(error);
            toast.error("Chyba při načítání příspěvků");
        }
    }

    const sendPost = async () => {
        try {
            await PostService.sendPost(text, date);
            toast.success("Příspěvek úspěšně přídán!");
            await getPosts();
            setText("")
        } catch (e) {
            toast.error("Chyba při přidávání příspěvku");
            console.log(e);
        }
    }

    const deletePost = async (e: any, post: Post) => {
        try {
            await PostService.deletePost(post);
            await getPosts()
            toast.success("Příspěvek úspěšně smazán!")
        } catch (e) {
            console.log(e);
            toast.error("Chyba při mazání příspěvku.")
        }
    }

    function getBackgroundColor(index: number) {
        if (index % 2 === 0) return "#FFF";
        else return "#EEF";
    }

    function handleDateChange(e: any) {
        setDate(e.target.value)
    }

    function handleTextChange(e: any) {
        setText(e.target.value)
    }

    useEffect(() => {
        getPosts()
    }, []);

    useEffect(() => {
        if (loading) return;
        else if (!user) return navigate("/login");
    }, [user, loading]);
    return (
        <>
            <Box sx={{
                justifyContent: 'space-between',
                display: "flex"
            }}>
                <Button onClick={() => navigate('/')}>
                    <HomeIcon fontSize={"large"}></HomeIcon>
                </Button>
                <Box style={{
                    padding: "1em",
                    display: "inline-block",
                    textAlign: "right"
                }}>
                    <a>Logged in as {user?.email}</a>
                    <br/>
                    <Button onClick={logout} sx={{
                        marginTop: "10px",
                        outline: "1px solid #BBB"
                    }}>
                        Logout
                    </Button>
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "space-evenly",
            }}>
                <Box>
                    <Typography variant={"h5"} sx={{
                        marginBottom: "30px"
                    }}>Příspěvky</Typography>
                    <Grid container spacing={1} sx={{
                        overflowY: "scroll",
                        width: "48em",
                        maxHeight: "20em",
                        boxShadow: "0 2px 4px rgba(0,0,0,.5)",
                    }}>
                        {posts.map((post, index: number) => (
                            <>
                                <Grid item xs={2} sx={{
                                    backgroundColor: getBackgroundColor(index),
                                }}>
                                    <a>{post.date}</a>
                                </Grid>
                                <Grid item xs={9} sx={{
                                    backgroundColor: getBackgroundColor(index),
                                }}>
                                    {post.text}
                                </Grid>
                                <Grid item xs={1} sx={{
                                    backgroundColor: getBackgroundColor(index),
                                }}>
                                    <IconButton onClick={e => deletePost(e, post)}>
                                        <CloseIcon sx={{
                                            color: "gray",
                                            "&:hover": {
                                                color: "red",
                                            }
                                        }}></CloseIcon>
                                    </IconButton>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Box>
                <Box>
                    <Typography variant={"h5"} sx={{
                        marginBottom: "20px"
                    }}>Přidat příspěvek</Typography>
                    <TextField value={date} onChange={handleDateChange}></TextField>
                    <br/>
                    <br/>
                    <TextField multiline value={text} rows={5} onChange={handleTextChange} sx={{
                        width: "48em"
                    }}></TextField>
                    <br/>
                    <br/>
                    <Button onClick={sendPost} sx={{
                        outline: "1px solid #BBB"
                    }}>Zveřejnit</Button>
                </Box>
            </Box>
            <ToastContainer/>
        </>
    );
}

export default Admin;