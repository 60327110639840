import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import "../index.css"
import {useEffect, useState} from "react";

const pages = ['O nás', 'Galerie', 'Otevírací doba', 'Kontakt'];
const references = ['#about', '#gallery', "#opening", "#contact"]

const ResponsiveAppBar = () => {

    const [menuColor, setMenuColor] = useState('#FFF');

    const useScrollPosition = () => {
        const [scrollPosition, setScrollPosition] = useState(0);

        useEffect(() => {
            const updatePosition = () => {
                setScrollPosition(window.pageYOffset);
            }
            window.addEventListener("scroll", updatePosition);
            updatePosition();
            return () => window.removeEventListener("scroll", updatePosition);
        }, []);

        return scrollPosition;
    };

    const scrollPosition = useScrollPosition();

    useEffect(() => {
       if(scrollPosition > window.innerHeight && menuColor !== "black"){
           setMenuColor("black")
       } else if(scrollPosition < window.innerHeight && menuColor !== "white") {
           setMenuColor("white")
       }
    });

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <AppBar position="fixed" sx={{
                display: {xs: 'none', md: 'flex'},
                height: "50px",
                paddingTop: "10px",
                backgroundColor: "white",
                color: "black"
            }}>

                <Box sx={{display: 'flex', justifyContent: 'space-around', margin: "0 20vw"}}>
                    {pages.map((page, index) => (
                        <Button sx={{
                            color: "black",
                            borderBottom: "2px solid transparent",
                            borderRadius: "0",
                            padding: "3px",
                            margin: "0 0",
                            "&:hover": {
                                borderBottom: "2px solid red",
                                backgroundColor: 'white'
                            }
                        }}>
                            <a href={references[index]} style={{
                                textDecoration: "none",
                                color: 'black'
                            }}>{page}</a>
                        </Button>
                    ))}
                </Box>
            </AppBar>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                 sx={{
                    position: "fixed",
                    zIndex: '10',
                }}
            >
                <MenuIcon sx={{color: menuColor}}/>
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: {xs: 'block', md: 'none'},
                }}
            >
                {pages.map((page, index) => (
                    <a href={references[index]} style={{textDecoration: 'none', color: 'black'}}>
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                    </a>
                ))}
            </Menu>
        </>
    );
};
export default ResponsiveAppBar;
