import * as React from 'react';
import Box from '@mui/material/Box';
import MapCard from "./MapCard";
import ContactCard from "./ContactCard";


const Information = () => {

    return (
        <>
            <Box id="contact" sx={{
                display: {xs: "flex", md: "flex"},
                justifyContent: "space-evenly",
                flexDirection: "column",
                height: {xs: "auto", md: "40em"},
                padding: {xs: "2.5em 0"}
            }}>
                <Box sx={{
                    width: "100vw",
                    display: "flex",
                    justifyContent: 'space-evenly',
                    flexDirection: "row",
                    flexWrap: "wrap",
                    rowGap: "5em"
                }}>
                    <MapCard></MapCard>
                    <ContactCard></ContactCard>
                </Box>
            </Box>
        </>
    );
};
export default Information;
