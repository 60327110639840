import * as React from 'react';
import Box from '@mui/material/Box';
import CurrentInformation from "./CurrentInformation";
import OpeningHours from "./OpeningHours";


const Essentials = () => {


    return (
        <>
            <Box id="contact" sx={{
                display: {xs: "flex", md: "flex"},
                justifyContent: "space-evenly",
                flexDirection: "column",
                height: {xs: "auto", md: "40em"},
                padding: {xs: "2.5em 0"}
            }}>
                <Box sx={{
                    width: "100vw",
                    display: "flex",
                    justifyContent: 'space-evenly',
                    flexDirection: "row",
                    flexWrap: "wrap",
                    rowGap: "5em"
                }}>
                    <CurrentInformation></CurrentInformation>
                    <OpeningHours></OpeningHours>
                </Box>
            </Box>
        </>
    );
};
export default Essentials;
