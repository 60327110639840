import React from 'react';
import './App.css';
import ResponsiveAppBar from "./component/ResponsiveAppBar";
import About from "./component/About"
import Box from "@mui/material/Box";
import backgroundImage from "./resources/backgroundimage.jpg"
import backgroundImageMobile from "./resources/backgroundimage_mobile.jpg"
import logo from "./resources/main_logo.png"
import logoMobile from "./resources/main_logo_mobile.png"
import Gallery from "./component/Gallery";
import OpeningHours from "./component/OpeningHours";
import Information from "./component/Information";
import Essentials from "./component/Essentials";

function App() {
    return (
        <>
            <ResponsiveAppBar></ResponsiveAppBar>
            <Box sx={{
                backgroundImage: {xs: `url(${backgroundImageMobile})`, md: `url(${backgroundImage})`},
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: 'space-around'
            }}>
                <Box sx={{flexDirection: 'column', justifyContent: 'space-around', display: 'flex'}}>
                    <Box component={"img"} sx={{width: "60vw", display: {xs: 'none', md: 'inline'}}} src={`${logo}`}/>
                    <Box component={"img"} sx={{width: "60vw", display: {xs: 'inline', md: 'none'}}} src={`${logoMobile}`}/>
                </Box>
            </Box>
            <About></About>
            <Gallery></Gallery>
            <Essentials></Essentials>
            <Information></Information>
            <footer id="copyright" style={{
                margin: '0 auto',
                textAlign: 'center',
                width: '40%',
                fontSize: '0.8em',
                fontWeight: "light",
                marginBottom: "5px",
                color: "#888"
            }}>Made by Vojtěch Drška © {new Date().getFullYear()}</footer>
        </>
    );
}

export default App;
