import Box from "@mui/material/Box";
import TextCard from "./TextCard";
import React from "react";

const About = () => {

    return (
        <>
            <Box id="about" sx={{
                display: {xs: "flex", md: "flex"},
                justifyContent: "space-evenly",
                flexDirection: "column",
                height: {xs: "auto", md: "40em"},
                padding: {xs: "5em 0"}
            }}>
                <Box sx={{
                    width: "100vw",
                    display: "flex",
                    justifyContent: 'space-evenly',
                    flexDirection: "row",
                    flexWrap: "wrap",
                    rowGap: "5em"
                }}>
                    <TextCard titleText={'Něco o krámku...'}
                              paragraph={'Naše záliba v korálcích rostla a z hobby se postupem času stal kamenný obchod. Ten jsme se rozhodli pojmenovat Korálky na hradbách vzhledem k jeho umístění. Nachází se totiž v podkroví starého domu, do nějž se vstupuje hradební věží a korálky zde opravdu visí na hradební zdi. '}/>
                    <TextCard titleText={'a co v něm najdete?'}
                              paragraph={'Jak hotové šperky, tak spoustu českých korálků různých velikostí, barev a tvarů, kovové přívěsky a komponenty pro výrobu bižuterie a samozřejme i místní keramiku.'}/>
                </Box>
            </Box>
        </>
    );
};
export default About;