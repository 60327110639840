import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
} from "firebase/firestore";

const firebaseConfig = {

    apiKey: process.env.REACT_APP_API_KEY,

    authDomain: process.env.REACT_APP_AUTH_DOMAIN,

    projectId: process.env.REACT_APP_PROJECT_ID,

    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,

    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,

    appId: process.env.REACT_APP_APP_ID,

    measurementId: process.env.REACT_APP_MEASUREMENT_ID

};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        console.log(user.uid)
        const q = query(collection(db, 'users/'), where('__name__', '==', user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await signOut(auth)
        }
    } catch (err: any) {
        await auth.signOut();
    }
};

const logout = () => {
    signOut(auth);

};
export {
    auth,
    db,
    signInWithGoogle,
    logout,
};