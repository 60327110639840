import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Card} from "@mui/material";
import {OpeningHoursBox} from "./OpeningHoursBox"

const OpeningHours = () => {

    return (
        <Card sx={{
            width: "20em",
            height: "28em",
            padding: "3em",
            fontSize: {xs: "0.7em", md: "1.3em"},
            boxSizing: "border-box",
        }}>
            <Typography sx={{fontSize: "1.5em"}}>Otevírací doba</Typography>
            <Box sx={{
                backgroundColor: "red",
                width: "7em",
                height: "2px"
            }}></Box>
            <br></br>
            <br></br>
            <OpeningHoursBox>
                <a>Pondělí</a>
                <a>10:00 - 13:00 14:00 - 17:00</a>
            </OpeningHoursBox>
            <OpeningHoursBox>
                <a>Úterý</a>
                <a>10:00 - 13:00 14:00 - 17:00</a>
            </OpeningHoursBox>
            <OpeningHoursBox>
                <a>Středa</a>
                <a>10:00 - 13:00 14:00 - 17:00</a>
            </OpeningHoursBox>
            <OpeningHoursBox>
                <a>Čtvrtek</a>
                <a>10:00 - 13:00 14:00 - 17:00</a>
            </OpeningHoursBox>
            <OpeningHoursBox>
                <a>Pátek</a>
                <a>10:00 - 13:00 14:00 - 17:00</a>
            </OpeningHoursBox>
            <OpeningHoursBox>
                <a>Sobota</a>
                <a>9:00 - 12:00</a>
            </OpeningHoursBox>
            <OpeningHoursBox>
                <a>Neděle</a>
                <a>Zavřeno</a>
            </OpeningHoursBox>
        </Card>
    );
};
export default OpeningHours;
