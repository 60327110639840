import React, {useEffect} from 'react';
import Button from "@mui/material/Button";
import {auth, signInWithGoogle} from "./firebase";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useAuthState} from "react-firebase-hooks/auth";
import {useNavigate} from "react-router-dom";

function Login() {

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (user) navigate("/admin");
    }, [user, loading]);

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: "center",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                height: "100vh"
            }}>
                <Typography variant={"h5"}>Please login</Typography>
                <Button onClick={signInWithGoogle} sx={{
                    margin: "20px",
                    padding: "10px",
                    backgroundColor: "red",
                    color: "white",
                    width: "250px",
                    "&:hover": {
                        backgroundColor: "#A33"
                    }
                }}>Sign in with google</Button>
            </Box>
        </>
    );
}

export default Login;