import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Card, Grid, Icon, Link} from "@mui/material";
import {useEffect, useState} from "react";
import Post from "../model/Post";
import PostService from "../service/PostService";
import {toast} from "react-toastify";


const CurrentInformation = () => {

    const [posts, setPosts] = useState<Post[]>([])
    const getPosts = async () => {
        try {
            const posts = await PostService.getPosts();
            setPosts(posts);
        } catch (error) {
            console.log(error);
            toast.error("Chyba při načítání příspěvků");
        }
    }

    useEffect(() => {
        getPosts()
    }, []);

    return (
        <Card sx={{
            width: "20em",
            height: "28em",
            padding: "3em",
            fontSize: {xs: "0.9em", md: "1.3em"},
            boxSizing: "border-box"
        }}>
            <Typography sx={{fontSize: "1.5em"}}>Aktuality</Typography>
            <Box sx={{
                backgroundColor: "red",
                width: "7em",
                height: "2px"
            }}></Box>
            <br></br>
            <br></br>
            <Grid container spacing={1} sx={{
                overflowY: "auto",
                fontSize: "0.8em",
                maxHeight: "22em"
            }}>
                {posts.map((post, index: number) => (
                    <>
                        <Grid item xs={12} sx={{
                        }}>
                            <a>{post.date}</a>
                        </Grid>
                        <Grid item xs={12} sx={{
                            borderBottom: "1px solid #BBB",
                            marginBottom: "5px",
                            paddingBottom: "5px"
                        }}>
                            {post.text}
                        </Grid>
                    </>
                ))}
            </Grid>
        </Card>
    );
};
export default CurrentInformation;
